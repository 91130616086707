import React from "react";
import { graphql, Link } from "gatsby";
import PageContent from "../components/pageContent";
import LayoutController from "../components/LayoutController";
import * as R from "ramda";
import "./style.scss";

const ContentPage = ({ data, location }) => {
    const returnPage = (objects) => {
      if(objects.contentfulPage !== null) {
        return objects.contentfulPage;
      }
    }
    const page = returnPage(data);
  
      return (
        <LayoutController 
        currentPath={location.pathname} 
        title={page.title} 
        description={R.path(['lead', 'lead'], page)}
        image={null}
        >
          <div className="page">
            <div className="pageContainer">
            <Link to="/" className="backLink">Takaisin karttanäkymään</Link>
              <h1>{page.title}</h1>
              <div className="pageLead">{page.lead.lead}</div>
              <div className="boxContainer">
                <div className="columnContent">
                  <PageContent data={R.path(['content', 'childMarkdownRemark', 'html'], page)}></PageContent>
                </div>
                <div className="columnContent">
                  <PageContent data={R.path(['contentColumn2', 'childMarkdownRemark', 'html'], page)}></PageContent>
                </div>
              </div>
              <div className="pageLead">
                <PageContent data={R.path(['lead2', 'childMarkdownRemark', 'html'], page)}></PageContent>
              </div>
              <div className="twocolumnContent">
                <PageContent data={R.path(['content2', 'childMarkdownRemark', 'html'], page)}></PageContent>
              </div>
            </div>
          </div>
        </LayoutController>
      );
    }
  
export default ContentPage;

export const pageQuery = graphql`
  query currentPage ($id: String!) {
    contentfulPage (id: { eq: $id }){
        id
        slug
        title
        lead {
            lead
        }
        content {
            content
            childMarkdownRemark {
                html
              }
        }
        contentColumn2 {
          contentColumn2
          childMarkdownRemark {
              html
            }
        }
        lead2{
          lead2
          childMarkdownRemark {
              html
            }
      }
        content2 {
          content2
          childMarkdownRemark {
              html
          }
      }
    }
}
`;